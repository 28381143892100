<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        md="12"
        sm="12"
      >
        <v-card>
          <v-toolbar
            dense
            elevation="0"
            height="50"
            color="transparent"
          >
            <h3 class="grey--text">
              {{ titulo }}
            </h3>
            <v-spacer />
            <v-btn
              align="right"
              color="success"
              dark
              small
              @click.prevent="saveInfo"
            >
              <v-icon class="mr-1">
                mdi-check-circle
              </v-icon> PROCESAR
            </v-btn>
          </v-toolbar>
        </v-card>

        <v-card class="mt-7 rounded-lg">
          <v-row class="mx-1">
            <v-col
              xs="12"
              md="12"
            >
              <v-text-field
                v-model="nomvend"
                label="Nombre del vendedor"
                hide-details
              />
            </v-col>
            
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="numvend"
                label="Clave Vendedor"
                hide-details
              />

              <p> Poner un selector para seleccionar el Idusuariosweb </p>
              <v-text-field
                v-model="idusuariosweb"
                label="Usuario Web"
              />

              <v-subheader>
                Validar un solo usuario por vendedor.
              </v-subheader>
            </v-col>
          </v-row>
        
        
          <v-img
            id="img1"
            max-height="250"
            contain
            class="img1"
            src="@/assets/vendedor1.png"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      nomvend:'',
      numvend:'',
      idvend:0,
      idusuariosweb: 0
    }
  },

  computed:{
    validaInfo(){
      return  this.nomvend   !== '' &&
        this.numvend !== ''
        // this.idvend !== ''
    },
  },

  created(){
    console.log('router', this.$route.params.vend )

    let ruta = this.$route.params.vend

    // this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.vend)
	        this.modo = 'Editar'
      this.titulo = 'Editar vendedor'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = 'Nueva vendedor'
	    }

  },

  methods:{
    ...mapActions("snackbar", ["showSnack"]),

    CargarInfo(vend){
      console.log("vend", vend)

      this.nomvend= vend.nomvend
      this.numvend = 	vend.numvend
      this.idvend =	vend.idvend
      this.idusuariosweb = vend.idusuariosweb

    },

    saveInfo(){
      console.log("saveInfo")
      if (!this.validaInfo){
	    	  this.showSnack({
	          text: "Debes registrar los datos del vendedor",
	          color: "orange",
	          timeout: 2000,
        	});
	     	  return
		    }

      var payload = {
        nomvend:     this.nomvend,
        numvend:     this.numvend,
        idvend:      this.idvend,
        idusuariosweb: parseInt(this.idusuariosweb),
      }

      console.log("payload", payload)
      // this.addCita(payload)

      //NUEVO
      if (this.nModo == 1) {
				   //Dedinir db name
        this.$http.post('v2/vend.add', payload).then( response =>{
		        console.log("response" ,response)

		        this.showSnack({
	            text: "Vendedor agregado correctamente",
	            color: "green",
	            timeout: 2000,
	          });

		        this.limpiarVendedor()
          // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
          	// this.snackbar = true ;
          // this.color="error"
          // this.text = "Problemas para grabar el cliente"
          console.log(error)
       			this.showSnack({
	            text: "Problema para agregar Vendedor"+ error,
	            color: "red",
	            timeout: 2000,
	          });
   
        })
      }

      //EDITAR
      if ( this.nModo == 2 ){
        this.$http.put('v2/vend.update', payload).then(response =>{
          this.showSnack({
	            text: "Vendedor actualizado correctamente	",
	            color: "green",
	            timeout: 2000,
	          });
          setTimeout (() => this.$router.push({name:'catvendedores'}),2000);

        }).catch(error=>{
          	this.showSnack({
	            text: "Problema para actualizar vendedor."+ error,
	            color: "red",
	            timeout: 2000,
	          });
          console.log(error)
        })
      }

    },

    limpiarVendedor(){
      this.nomvend = ''
      this.numvend = ''
      this.idvend = ''
    }

  }
}
</script>

<style scoped>
  .img1{
    top: -30px;
  }
</style>