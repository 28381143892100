<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      color="blue-grey lighten-5"
      right
      class="elevation-0"
      width="330"
      temporary
    >
      <vendedor />
    </v-navigation-drawer>
    <v-card>
      <v-toolbar
        dark
        dense
        color="blue-grey"
      >
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="blue-grey lighten-3"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
            >
              mdi-account-tie
            </v-icon>
          </v-avatar>
        </v-col>
        <h3> Catálogo de Vendedores</h3>
        <v-spacer />
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-2"
          @click.stop="vendedor()"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-subheader>
            <h2 class="ma-4 pt-2 grey--text">
              Vendedores
            </h2>
            <v-spacer />
            <v-col cols="3">
              <v-text-field
                v-model="search"
                class="mx-2"
                append-icon="mdi-magnify"
                label="Filtrar"
                single-line
                hide-details
              />
            </v-col>
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                heigth="400"
                :headers="headers"
                :items="vend"
                :search="search"
                dense
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="text-sm font-semibold text-white"
                    @dblclick="editar(item)"
                  >
                    <td>{{ item.nomvend }}</td>
                    <td>{{ item.numvend }}</td>
                    <td>{{ item.idvend }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vendedor from "@/views/crm/vendedores/vendedor"
import { mapActions, mapGetters} from 'vuex'
export default {
  components:{
    vendedor
  },
  data () {
    return {
      drawer: false,
      search: '',
      vend: [],
      headers: [
        { text: 'Nombre del vendedor', value: 'nomvend' },
        { text: 'Clave', value: 'numvend' },
        { text: 'Usuario Web', value: 'idvend' },
      ],
    }
  },
  created(){
    this.consultar()
  },

  methods: {
    ...mapActions('vend',['setVendedor']),
    consultar(){
      // limpiar
      this.vend= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idvend
      // console.log('consultar', id)

      // let payload = {
      //   nomvend: this.nomvend,
      //   numvend : this.numvend,
      //   idvend: this.idvend,
      // }

      this.$http.get("v2/vend.list").then(response=>{
        console.log(response)

        if (response.data.error===null){
          this.vend = response.data.result

          this.vend.forEach( element => {
            // element.fechastart = element.fechastart.substr(0,10)
            // element.hora = element.fechastart.substr(11,17)
          })

          console.log("vend", this.vend)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
      
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'vendedor', params: {vend:item}})
    },

    vendedor(){
      // console.log("Vista lateral",opor1)
      // Mandar llamar al Action.
      // this.setVendedor (opor1)

      this.drawer = !this.drawer
    }
  }
}

</script>


<style scoped>
.avatar{
  top: -10px;
}
</style>